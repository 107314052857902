import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {

    const history = useHistory();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    const login = async (loginData, cb) => {
        const res = await fetch(`${process.env.REACT_APP_APISERVER}/api/auth/signin`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loginData)
        });

        const result = await res.json();

        if (res.status === 200 && result.data) {
            setUser(result.data);
            setIsLoggedIn(true);
            if (cb) {
                cb();
            }
        } else {
            setUser(null);
            setIsLoggedIn(false);
            
        }
    }

    const loginGoogle = async (cb) => {
        window.location = `${process.env.REACT_APP_APISERVER}/api/auth/google`;

        // window.addEventListener('message', (event) => {
        //     console.log("postMessage recieved: ", event.data);
        // })

    }

    const fetchIsLoggedIn = async (cb) => {
        const response = await fetch(`${process.env.REACT_APP_APISERVER}/api/auth/check/signedin`, {
            credentials: 'include',
        });

        const result = await response.json();

        if (response.status == 200) {
            setUser(result.data);
            setIsLoggedIn(true);
            if (cb) cb();
        }
        else {
            setUser(null);
            setIsLoggedIn(false);
            console.log(result.msg);
            if (cb) cb();
        }
    }

    const fetchIsAdmin = async () => {
        const response = await fetch(`${process.env.REACT_APP_APISERVER}/api/auth/check/signedin`, {
            credentials: 'include',
        });

        const result = await response.json();

        if (response.status == 200 && result.data.role == "ADMIN") {
            return true;
        }
        else {
            return false;
        }
    }

    const logout = async (cb) => {
        const response = await fetch(`${process.env.REACT_APP_APISERVER}/api/auth/signout`, {
            credentials: 'include'
        });
        const result = await response.json();
        if (response.status == 200) {
            setIsLoggedIn(false);
            setUser(null);
            if (cb) {
                cb();
            }
        } else {
            console.log(result.msg);
        }
    }  
    
    useEffect(() => {
        fetchIsLoggedIn();
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, isAdmin, user, fetchIsLoggedIn, fetchIsAdmin, logout, loginGoogle, login }}>
            {children}
        </AuthContext.Provider>
     );
}
 
export default AuthContextProvider;