import React, { lazy, useContext, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'

// Contexts

const Layout = lazy(() => import('./containers/Layout'))
const NotAuthorized = lazy(() => import('./pages/NotAuthorized'))

function App() {

  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to={"/app"} />
          <Route exact path={"/notauthorized"} component={NotAuthorized} />
        </Switch>
      </Router>
    </>
  )
}

export default App
